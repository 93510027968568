// Component states
import states from './settings-navbar-modifier.state.js'

// Services
import { applyDrag, componentBuilder } from '@/services/utils/utils.service'
import { openEditPanel, landingPageMode } from '@/services/states/states.service'

// Components
import MdiEdit from 'vue-material-design-icons/SquareEditOutline.vue'
import MdiActionActive from 'vue-material-design-icons/Link.vue'
import MdiActionEmpty from 'vue-material-design-icons/LinkOff.vue'
import MdiDelete from 'vue-material-design-icons/Delete.vue'
import MdiCursorMove from 'vue-material-design-icons/CursorMove.vue'
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'
import { Container, Draggable } from 'vue-smooth-dnd'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-navbar-mj'

// Vue@Properties
const props = {
  conf: Object,
  mjml: Object
}
// Vue@Properties

// Vue@subComponents
const components = {
  MdiEdit,
  MdiActionActive,
  MdiActionEmpty,
  MdiDelete,
  MdiCursorMove,
  Container,
  Draggable,
  custColorPicker
}
// Vue@subComponents

// Vue@data
const data = function () {
  return {
    states
  }
}
// Vue@data

// Methods
const methods = {

  // Func@openModal
  /**
   * Open Modal
   * @param  {Integer} index (Panel index)
   */
  openModal (index) {
    openEditPanel({
      parent: { mjml: this.mjml },
      contents: this.mjml.children,
      isModal: true,
      index
    })
  },
  // Func@openModal

  /**
   * Get link URL when this one isn't on error
   * @param  {Object} link (Link)
   *
   * @return {String}       (src || placeholder)
   */
  getUrl (link) {
    return link.attributes.href
  },

  /**
   * Get link content when this one isn't on error
   * @param  {Object} link (Link)
   *
   * @return {String}       (src || placeholder)
   */
  getLinkContent (link) {
    return link.content
  },

  // Func@addLink
  /**
   * Add new link
   */
  async addLink () {
    const firstItem = { ...this.mjml.children[0] }
    const newLinkPanel = await componentBuilder({
      componentId: 'mj-navbar-link',
      withId: true,
      overwrite: {
        'mj-navbar-link': {
          attributes: {
            'font-family': firstItem.attributes['font-family'],
            'line-height': firstItem.attributes['line-height'],
            'font-size': firstItem.attributes['font-size'],
            color: firstItem.attributes.color
          }
        }
      }
    })
    this.mjml.children.push(newLinkPanel)
  },
  // Func@addLink

  // Func@deleteLink
  /**
   * Delete existing link
   * @param  {Integer} index (panel position)
   */
  deleteLink (index) {
    if (this.mjml.children.length > 1) this.mjml.children.splice(index, 1)
  },
  // Func@deleteLink

  // Func@onDrop
  /**
   * To get the droped list and replace the current
   * @param  {Object} dropResult (D&D params)
   */
  onDrop (dropResult) {
    const newArray = applyDrag(this.mjml.children, dropResult)
    this.mjml.children.splice(0, this.mjml.children.length, ...newArray)
  },
  // Func@onDrop

  // Func@getPayload
  /**
   * Get DND payload
   * @param  {Number} index (position)
   *
   * @return {Object}       (payload)
   */
  getPayload (index) {
    return this.mjml.children[index]
  }
  // Func@getPayload
}

// Computed methods
const computed = {
  /**
   * Check DND mode
   */
  isLandingMode () {
    return landingPageMode().get()
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  computed,
  components
}
